<template>
  <div class="grid crud-demo">
    <div class="col-12">
      <div class="card">
        <Toast/>
        <Toolbar class="mb-4">
          <template v-slot:end>
            <div class="my-2">
              <Button label="Novo" icon="pi pi-plus" class="p-button-success mr-2" @click="$router.push({name: 'procedimento'})" />
            </div>
          </template>
        </Toolbar>

        <DataTable ref="dt" :value="procedures" dataKey="id"
                   :paginator="true"
                   :rows="10"
                   :filters="filters"
                   :lazy="true"
                   :loading="loading"
                   :total-records="totalRecords"
                   @page="findAll($event)"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                   currentPageReportTemplate="Total {totalRecords} produtos"
                   responsiveLayout="scroll">
          <template #empty>
            Nenhum registro encontrado.
          </template>
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Gerenciamento de Procedimento</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText @change="findAll(page)" v-model="pesquisar" placeholder="Pesquisar..." />
                            </span>
            </div>
          </template>
          <Column field="name" header="Nome" :sortable="true">
            <template #body="slotProps">
              <Skeleton v-show="skeletor"></Skeleton>
              <span class="p-column-title">Nome</span>
              <span v-show="!skeletor">{{slotProps.data.name}}</span>
            </template>
          </Column>
          <Column field="price" header="Valor" :sortable="true">
            <template #body="slotProps">
              <Skeleton v-show="skeletor"></Skeleton>
              <span class="p-column-title">Valor</span>
              <span v-show="!skeletor">{{new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(slotProps.data.price)}}</span>
            </template>
          </Column>
          <Column field="metricsType" header="Produtos">
            <template #body="slotProps">
              <Skeleton v-show="skeletor"></Skeleton>
              <span class="p-column-title">Produtos</span>
              <div v-show="!skeletor" class="flex align-items-center" v-for="prod in slotProps.data.products" :key="prod.id">
                <span v-show="!skeletor" class="bg-orange-200 mr-2 mt-2 text-black-400 border-round py-1 px-2 text-sm"
                >{{ prod.name }}</span>
              </div>

            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button v-show="!skeletor" icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="edit(slotProps.data)" />
              <Button v-show="!skeletor" icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDelete(slotProps.data)" />
            </template>
          </Column>
        </DataTable>

        <Dialog v-model:visible="deleteProcedureDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="procedure">Tem certeza de que deseja excluir <b>{{ procedure.name }}</b>?</span>
          </div>
          <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="deleteProcedureDialog = false"/>
            <Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deletar(procedure.id)" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import ProcedureService from "@/service/ProcedureService";

export default {
  data() {
    return {
      procedures: null,
      deleteProcedureDialog: false,
      filters: {},
      procedureService: null,
      procedure: null,
      loader: null,
      totalRecords: 0,
      loading: false,
      skeletor: false,
      pesquisar: null,
      page: {
        originalEvent: {
          rows: 10,
          page: 0
        }
      },
    }
  },
  computed: {

  },
  created() {
    this.procedureService = new ProcedureService();
    this.initFilters();
  },
  async mounted() {

    this.loading = true;
    await this.findAll(this.page);
    this.loading = false;
  },

  methods: {

    async findAll(event) {
      let response = null;
      this.skeletor = true;
      if(this.pesquisar) {
        response = await this.procedureService.findAll(event.originalEvent.rows, event.originalEvent.page, this.pesquisar);
      } else {
        response = await this.procedureService.findAll(event.originalEvent.rows, event.originalEvent.page);
      }
      if (response.status === 200 || response.status === 204) {
        this.procedures = response.data.content;
        this.totalRecords = response.data.totalElements;
      }
      this.skeletor = false;
    },
    edit(produto) {
      this.$router.push({name: 'procedimento', query: {id: produto.id}});
    },
    confirmDelete(product) {
      this.procedure = product;
      this.deleteProcedureDialog = true;
    },
    async deletar(id) {
      this.loading = true;
      try {
        const response = await this.procedureService.delete(id);
        if(response.status === 200) {
          this.deleteProcedureDialog = false;
          this.contact = {};
          this.$toast.add({
            severity: 'success',
            summary: 'Sucesso',
            detail: 'Procedimento removido com sucesso,',
            life: 3000
          });
          await this.findAll(this.page);
        }
      } catch(e) {
        this.deleteProcedureDialog = false;
        this.$toast.add({
          severity: 'warn',
          summary: 'Alerta',
          detail: 'Procedimento não pode ser removido, pois possuí vinculo com outros dados,',
          life: 4000
        });
      }
      this.loading = false;
    },

    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.procedures.length; i++) {
        if (this.procedures[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },

    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    }
  }
}
</script>

<style scoped lang="scss">
.product-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-true {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-false {
    background: #FFCDD2;
    color: #C63737;
  }
}
</style>
